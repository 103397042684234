import React from "react"
import styled from "@emotion/styled"

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const AutoFlex = ({ children, ...props }) => {
  return <Flex {...props}>{children}</Flex>
}

export default AutoFlex
