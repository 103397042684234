import React, { useEffect, useRef } from "react"

export const useDidMount = callback => useEffect(callback, [])

export const useWillMount = callback => {
  const willMount = useRef(true)
  if (willMount.current) {
    callback()
  }
  useDidMount(() => {
    willMount.current = false
  }, [])
}
