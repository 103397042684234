/**@jsx jsx*/
import React from "react"
import { useGlobalState } from "../utilities/globalContext"
import { useWillMount } from "../utilities/lifecycleHooks"
import { navigate } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Card from "../components/card"
import AutoFlex from "../components/autoflex"
import { Col } from "boostly-ui2"
import {
  TitlePrimary,
  TitleSecondary,
  TitleTertiary,
} from "../components/titles"
import { largeMT, medMT, smallMT } from "../components/spacing"
import { css, jsx } from "@emotion/core"
import { ButtonPrimary } from "../components/buttons"

const CardButton = styled(Card)`
  flex-grow: 1;
  cursor: pointer;
  box-shadow: 0 8px 10px hsla(0, 0%, 0%, 0.2), 0 5px 10px hsla(0, 0%, 0%, 0.1);
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
    transform: scale(1.01px);
    box-shadow: 0 8px 30px hsla(0, 0%, 0%, 0.3), 0 5px 10px hsla(0, 0%, 0%, 0.1);
  }
  padding: 20px 0px;
`

// const Title = styled(TitlePrimary)`
//   padding: 40px 0px;
//   @media (max-width: 500px) {
//     padding: 20px 0px;
//   }
// `

const Content = styled.section`
  grid-column-start: 2;
  grid-row: 1 / 3;
  z-index: 10;
  padding-top: 40px;
  @media (max-width: 500px) {
    padding-top: 10px;
  }
`

const actions = {
  signin: "signin",
  signup: "signup",
}

const Index = () => {
  const { user } = useGlobalState()

  useWillMount(() => {
    user && navigate("/dashboard")
  }, [])
  const handleButton = dest => {
    switch (dest) {
      case actions.signin:
        navigate("/login")
        break
      case actions.signup:
        navigate("/signup")
        break
      default:
        return
    }
  }
  return (
    <Layout>
      <Content>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <ButtonPrimary
            css={css`
              align-self: flex-end;
            `}
            onClick={() => handleButton(actions.signin)}
          >
            Log in
          </ButtonPrimary>
          <TitlePrimary
            css={css`
              ${medMT};
              font-weight: bold;
            `}
          >
            Poqet is the easiest way to securely sell digital products online.
          </TitlePrimary>
          <TitleSecondary css={largeMT}>
            So easy that it's faster for you to sign up than for us to
            explain.
          </TitleSecondary>
          <ButtonPrimary
            css={css`
              ${largeMT};
              align-self: flex-start;
            `}
            onClick={() => handleButton(actions.signup)}
          >
            Sign me up
          </ButtonPrimary>
        </div>
        {/* <AutoFlex>
          <CardButton onClick={() => handleButton(actions.signin)}>
            <Col x space="around">
              <TitleSecondary>Sign in</TitleSecondary>
              <img
                src={require(`../images/signin.png`)}
                alt="signin"
                height="180px"
                width="250px"
              />
            </Col>
          </CardButton>
          <CardButton
            css={css`
              margin-left: 50px;
              @media (max-width: 500px) {
                margin-left: 0px;
                margin-top: 20px;
              }
            `}
            onClick={() => handleButton(actions.signup)}
          >
            <Col x space="around">
              <TitleSecondary>Sign up</TitleSecondary>
              <img
                src={require(`../images/signup.png`)}
                alt="signin"
                height="180px"
                width="250px"
              />
            </Col>
          </CardButton>
        </AutoFlex> */}
      </Content>
    </Layout>
  )
}

export default Index
